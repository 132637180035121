import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConnectedState, connectedKey } from './connected.reducers';
import { checkEquipmentForActiveConnectedPlusContracts } from '@connected/contracts/utils';

const selectConnected = createFeatureSelector<ConnectedState>(connectedKey);

export namespace ConnectedSelectors {
  export const getEquipmentDetails = createSelector(
    selectConnected,
    (state) => state.equipmentDetails,
  );
  export const getServicePartnerInfo = createSelector(
    selectConnected,
    (state) => state.equipmentDetails.service_partner_info,
  );
  export const getHasTelematics = createSelector(
    selectConnected,
    (state) => state.equipmentDetails?.has_telematics,
  );
  export const getEquipmentType = createSelector(
    selectConnected,
    (state) => state.equipmentDetails?.equipment_type_icon,
  );
  export const getSubEquipmnetList = createSelector(selectConnected, (state) =>
    state.equipmentDetails.operating_hours?.map((item) => item?.type || ''),
  );
  export const getIsEquipmentDetailsLoading = createSelector(
    selectConnected,
    (state) => state.isEquipmentDetailsLoading,
  );
  export const getHasEquipmentDetailsFailed = createSelector(
    selectConnected,
    (state) => state.hasEquipmentDetailsFailed,
  );

  export const getHasEquipmentActiveConnectedContract = createSelector(
    selectConnected,
    (state) =>
      checkEquipmentForActiveConnectedPlusContracts(
        state.equipmentDetails.contracts,
      ),
  );

  export const getCCURL = createSelector(
    selectConnected,
    (state) => state?.ccURL,
  );
}
