import { ServicePartnerInfoBaseResponse } from '@connected/fleet-service/models';

export enum TelematicState {
  Retrofitable = 'Retrofitable',
  Telematic = 'Telematic',
  NoTelematic = 'NoTelematic',
}

export enum UpgradeableState {
  Expired = 'Expired',
  ExpiredSoon = 'ExpiredSoon',
  NoContract = 'NoContract',
  ActiveContract = 'ActiveContract',
  ActiveTrial = 'ActiveTrial',
}
export interface UpgradeableContracts {
  carrier: string;

  equipment_number: string;

  equipment?: string | null;

  equipment_type_icon: string;

  telematic_state: TelematicState;

  upgradeable_state: UpgradeableState;

  service_partner_info?: ServicePartnerInfoBaseResponse;
}
